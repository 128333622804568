import { get, post } from '@/libs/axios'

// 查询资源
export const QueryMuneResource = () => get('/sys/app/QueryMenuResource')

export const ListResource = () => get('/sys/app/ListResource')

// 添加资源
export const CreateResource = params => post('/sys/app/CreateResource', params)
// 删除资源
export const DeleteResource = id => get(`/sys/app/resource/delete?id=${id}`)

// 查询资源接口
export const ListResourcePerm = id => get(`/sys/app/ListResourcePerm?resourceId=${id}`)
// 添加资源
export const CreateResourcePerm = params => post('/sys/app/CreateResourcePerm', params)
// 更新资源
export const UpdateResourcePerm = params => post('/sys/app/UpdateResourcePerm', params)
// 删除资源
export const DeleteResourcePerm = params => post('/sys/app/DeleteResourcePerm', params)

// 添加资源或修改资源
export const saveOrUpdateResource = params => {
  const submitUrl = params.id ? '/sys/app/resource/update' : '/sys/app/resource/create'
  return post(submitUrl, params)
}
// 企业授权
export const AuthorCompanyResource = params => post('/sys/app/AuthorCompanyResource', params)
// 企业资源列表
export const ListCompanyResource = eid => get(`/sys/app/ListCompanyResource?eid=${eid}`)
// 企业资源ID列表
export const GetCompanyResourceIdList = eid => get(`/sys/app/GetCompanyResourceIdList?eid=${eid}`)
