<template>
  <div class="auth-wrap">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="120px"
      class="mr10"
    >
      <b-card>
        <b-row>
          <b-col :span="12">
            <el-form-item
              label="企业系统权限"
              prop="resourceIdList"
              :rules="[ { required: true, message: '企业系统权限不能为空', trigger: 'change' }]"
            >
              <el-tree
                ref="elTree"
                :data="resources"
                accordion
                show-checkbox
                node-key="id"
                :check-strictly="false"
                :default-expand-all="false"
                :default-checked-keys="checkedIds"
                :props="{ label: 'name', children: 'children' }"
                @check-change="checkChange"
              />
            </el-form-item>

          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <el-row class="title">
          企业模板
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="隐私政策模板">
              <el-select
                v-model="form.privacyPolicyTemplateCodeList"
                multiple
                placeholder="请选择模板"
              >
                <el-option
                  v-for="item in privacyPolicyTemplates"
                  :key="item.code"
                  :label="item.templateName"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="隐私声明模板">
              <el-select
                v-model="form.privacyStatementTemplateCodeList"
                multiple
                placeholder="请选择模板"
              >
                <el-option
                  v-for="item in privacyStatements"
                  :key="item.code"
                  :label="item.templateName"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="制度流程模板">
              <el-select
                v-model="form.regimeTemplateCodeList"
                multiple
                placeholder="请选择模板"
              >
                <el-option
                  v-for="item in regimeTemplates"
                  :key="item.code"
                  :label="item.templateName"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="协议模板">
              <el-select
                v-model="form.agreementTemplateCodeList"
                multiple
                placeholder="请选择模板"
              >
                <el-option
                  v-for="item in agreementTemplates"
                  :key="item.code"
                  :label="item.templateName"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="隐私组织模板">
              <el-select
                v-model="form.privacyOrgTemplateId"
                placeholder="请选择模板"
              >
                <el-option
                  v-for="item in privacyOrgTemplates"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="评估问卷">
              <el-select
                v-model="form.assessTemplateCodeList"
                multiple
                placeholder="请选择评估模板"
              >
                <el-option
                  v-for="(item,index) in assessTemplates"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="词典">
              <el-cascader-panel
                ref="elCascader"
                v-model="form.privacyClassIdList"
                :options="classOptions"
                style="width: 100%"
                :show-all-levels="false"
                :props="{ emitPath: false, multiple: true, value: 'id', label: 'label' }"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </b-card>
      <b-card>
        <el-row class="title">
          企业系统管理员信息
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              label="管理员账号"
              prop="adminAccount"
              :rules="[ { required: true, message: '管理员账号不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.adminAccount"
                placeholder="管理员账号（建议使用邮箱，账号默认角色 系统管理员）"
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="管理员密码"
              prop="adminPassword"
              :rules="[ { required: true, message: '管理员密码不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.adminPassword"
                placeholder="管理员密码"
                type="password"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </b-card>
      <b-card>
        <el-row class="title">
          授权信息
        </el-row>
        <el-row v-if="type !== 'sass'">
          <el-form-item
            label="机器码"
            prop="machineCode"
            :rules="[ { required: true, message: '机器码不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.machineCode"
            />
          </el-form-item>
        </el-row>
        <el-row v-else>
          <el-form-item label="状态">
            <el-switch
              v-model="form.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
            />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item
            label="授权时间"
            prop="authorizationDuration"
            :rules="[ { required: true, message: '授权时间不能为空', trigger: 'change' }]"
          >
            <div style="display: flex;">
              <el-select
                v-model="form.authorizationDuration"
                style="width: 30%"
                @change="setAuthTime()"
              >
                <el-option
                  v-for="item in timeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <div
                v-if="form.authorizationDuration !== 6"
                style="margin-left: 20px;"
              >
                <el-date-picker
                  v-model="form.authorizationBeginTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                  @change="setAuthTimeType"
                  :picker-options="pickerOptionsStart"
                />
                至
                <el-date-picker
                  v-model="form.authorizationEndTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期"
                  @change="setAuthTimeType"
                  :picker-options="pickerOptionsEnd"
                />
              </div>
            </div>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="备注">
            <el-input
              v-model="form.authorizationRemark"
              type="textarea"
            />
          </el-form-item>
        </el-row>
      </b-card>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button
        type="primary"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </div>
</template>

<script>
import { error, success, treeDataTranslate } from '@core/utils/utils'
import {
  getSassEnterprise,
  getAutonomyEnterprise,
  authSassEnterprises,
  authAutonomyEnterprises,
} from '@/api/enterprises/enterprises'
import { ListResource } from '@/api/system/resource/resource'
import { GetAssessTemplateOptions, GetOrgTemplateList } from '@/api/knowledgeBase/template'
import { GetAllClass } from '@/api/dictLibrary/dictLibrary'
import Moment from 'moment'
import { GetQuestionnaireOptions } from '@/api/assess/questionTemplate'

export default {
  data() {
    return {
      pwdType: true,
      emitPath: false,
      loading: false,
      statusOption: [
        { label: '启用', value: 1 },
        { label: '停用', value: 0 },
      ],
      timeOption: [
        { label: '两周', value: 1 },
        { label: '一个月', value: 2 },
        { label: '一年', value: 3 },
        { label: '两年', value: 4 },
        { label: '三年', value: 5 },
        { label: '无限期', value: 6 },
        { label: '自定义', value: 7 },
      ],
      form: {
        id: Number(this.$route.query.id),
        privacyOrgTemplateId: undefined,
        resourceIdList: [],
        adminAccount: '',
        adminPassword: '',
        authorizationBeginTime: undefined,
        authorizationEndTime: undefined,
        authorizationRemark: '',
        authorizationDuration: 1,
        status: 1,
        agreementTemplateCodeList: [],
        assessTemplateCodeList: [],
        privacyClassIdList: [],
        privacyPolicyTemplateCodeList: [],
        privacyStatementTemplateCodeList: [],
        regimeTemplateCodeList: [],
      },
      resources: [],
      roles: [],
      resourceCode: [],
      checkedIds: [],
      parentIdList: [],
      assessTemplates: [],
      privacyPolicyTemplates: [], // 隐私政策
      privacyStatements: [], // 隐私声明
      regimeTemplates: [],
      agreementTemplates: [], // 合作协议
      privacyOrgTemplates: [], // 隐私组织
      classOptions: [], // 词典
      privacyClass: [],
      templates: [],
      type: this.$route.query.type,
      selectRoleCode: '',
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.form.authorizationEndTime) {
            return time.getTime() < Date.now() - 8.64e7 || time.getTime() > new Date(this.form.authorizationEndTime).getTime()
          }
          return time.getTime() < Date.now() - 8.64e7
        },
      },
      pickerOptionsEnd: {
        disabledDate: time => time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.form.authorizationBeginTime).getTime() - 24 * 60 * 60 * 1000,
      },
    }
  },
  created() {
    this.getEnterpriseInfo()
    this.getResources()
    // 隐私政策下拉
    this.getAssessTemplateOptions(1, this.privacyPolicyTemplates)
    // 隐私声明下拉
    this.getAssessTemplateOptions(2, this.privacyStatements)
    // 制度流程
    this.getAssessTemplateOptions(3, this.regimeTemplates)
    // 合作协议
    this.getAssessTemplateOptions(4, this.agreementTemplates)
    this.getOrgTemplateList()
    this.getClassOptions()
    this.getAssessTemplats()
  },
  mounted() {
    this.navActiveInit(1, this.type === 'sass' ? 'SASS用户管理' : '独立部署管理', '企业管理')
  },
  destroyed() {
    this.navActiveInit(2, this.type === 'sass' ? 'SASS用户管理' : '独立部署管理', '企业管理', this.type === 'sass' ? '/sassenterprise' : '/enterprises')
  },
  methods: {
    checkChange() {
      const checkedKeys = this.$refs.elTree.getCheckedKeys()
      const halfCheckedKeys = this.$refs.elTree.getHalfCheckedKeys()
      this.form.resourceIdList = [...checkedKeys, ...halfCheckedKeys]
    },
    setAuthTime() {
      const authTimeDaysObj = {
        1: 14, // 两周
        2: 30, // 一个月
        3: 365, // 一年
        4: 365 * 2, // 两年
        5: 365 * 3, // 三年
        6: 0, // 无限期
        7: -1, // 自定义
      }
      const authTimeDaysObjElement = authTimeDaysObj[this.form.authorizationDuration]
      if (authTimeDaysObjElement > 0) {
        this.form.authorizationBeginTime = Moment().format('yyyy-MM-DD HH:mm:ss')
        this.form.authorizationEndTime = Moment(Moment().add(authTimeDaysObj[this.form.authorizationDuration], 'days')).format('yyyy-MM-DD HH:mm:ss')
      }
    },
    setAuthTimeType() {
      const number = this.form.authorizationEndTime - this.form.authorizationBeginTime
      const number1 = number / (1000 * 60 * 60 * 24)
      if (number1 === 14) {
        this.form.authorizationDuration = 1
      } else if (number1 === 30) {
        this.form.authorizationDuration = 2
      } else if (number1 === 365) {
        this.form.authorizationDuration = 3
      } else if (number1 === 365 * 2) {
        this.form.authorizationDuration = 4
      } else if (number1 === 365 * 3) {
        this.form.authorizationDuration = 5
      } else {
        this.form.authorizationDuration = 7
      }
    },
    getClassOptions(params) {
      GetAllClass(params).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.classOptions = resData.data
        }
      })
    },
    getOrgTemplateList() {
      GetOrgTemplateList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.privacyOrgTemplates = resData.data
        }
      })
    },
    getAssessTemplateOptions(templateType, options) {
      GetAssessTemplateOptions({ templateType }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            options.push(r)
          })
        }
      })
    },
    back() {
      this.$router.push(this.type === 'sass' ? '/sassenterprise' : '/enterprises')
    },
    getAssessTemplats() {
      GetQuestionnaireOptions({ assessCategoryCode: '' }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.assessTemplates = resData.data
        }
      })
    },
    getResources() {
      ListResource().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.resources = treeDataTranslate(resData.data, 'id', 'parentId')
          resData.data.forEach(ele => this.parentIdList.push(ele.parentId))
        }
      })
    },
    getEnterpriseInfo() {
      this.loading = true
      const apiName = this.type === 'sass' ? getSassEnterprise : getAutonomyEnterprise
      this.form.id = Number(this.$route.query.id)
      apiName({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = { ...resData.data }
          if (this.form.authorizationDuration === 0) {
            this.form.authorizationDuration = ''
            // this.form.authorizationDuration = undefined
          }
          if (this.form.privacyOrgTemplateId === 0) {
            this.form.privacyOrgTemplateId = ''
            // this.form.privacyOrgTemplateId = undefined
          }
          if (this.form.resourceIdList) {
            this.checkedIds = this.form.resourceIdList.filter(id => !this.parentIdList.includes(id))
          }
          console.log(this.form)
        }
      })
      this.loading = false
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const checkedKeys = this.$refs.elTree.getCheckedKeys()
          const halfCheckedKeys = this.$refs.elTree.getHalfCheckedKeys()
          this.form.resourceIdList = [...checkedKeys, ...halfCheckedKeys]
          if (this.form.authorizationDuration === 6) {
            this.form.authorizationBeginTime = undefined
            this.form.authorizationEndTime = undefined
          }
          this.form.privacyOrgTemplateId = this.form.privacyOrgTemplateId ? this.form.privacyOrgTemplateId : 0
          this.loading = true
          const apiName = this.type === 'sass' ? authSassEnterprises : authAutonomyEnterprises
          apiName(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        } else {
          this.scrollIntoView()
        }
      })
    },
    scrollIntoView(className = 'is-error', block = 'center') {
      Promise.resolve().then(() => {
        const target = document.getElementsByClassName(className)
        if (target.length) {
          target[0].scrollIntoView({
            behavior: 'smooth', // 平滑过渡 值有auto、instant,smooth，缓动动画（当前是慢速的）
            block, // 上边框与视窗顶部平齐。默认值 值有start,center,end，nearest，当前显示在视图区域中间
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
.title {
  font-size: 16px;
  padding-bottom: 10px;
}
.role {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
}
.role-active {
  background: #eee;
}
.role p {
  width: 80%;
  height: 30px;
  line-height: 30px;
  margin: 0;
  cursor: pointer;
}
.auth-wrap .el-cascader-menu__wrap{
  height: auto !important;
}
</style>
